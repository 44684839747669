<template>
    <div class="ce-content">

        <div class="row ce-row">

            <!-- First col ------------------------>
            <div class="col-md-12">

                <!-- ce-content-inner ------------------------------>
                <div class="ce-content-inner">
                    <div class="row roles-page-wrap client-roles-wrap">
                        <div class="col-md-12 roles-page-header">
                            <h3 class="ce-create-client-list-card title">Clients Roles</h3>
                            <div class="ce-create-role-btn-wrap">
                                <button class="btn ce-btn-create-role-submit" data-toggle="modal"
                                        data-target="#ce-create-role">
                                    Create new
                                </button>
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <!-- Create role Modal -->
                            <div class="modal fade ce-create-role" id="ce-create-role" tabindex="-1" role="dialog"
                                 aria-labelledby="ce-create-role-modal" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 v-if="isEdit" class="modal-title" id="ce-role-edit-modal">Edit client role</h5>
                                            <h5 v-else class="modal-title" id="ce-role-create-modal">Create new client role</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="reset()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="ce-content-inner client-roles-wrap">
                                                <div class="roles-client-select">
                                                    <label for="roles-client-select-id">Client </label>
                                                    <select v-model="newClientRoles.sso_client_id" name="" @change="getClientScopes()" id="roles-client-select-id">
                                                      <option v-for="data in clientList" :key="data.id" :value="data.id">{{data.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="cr-name">
                                                    <label for="cr-name-input">Role Name</label>
                                                    <input type="text" v-model="newClientRoles.name" id="cr-name-input" placeholder="Enter Role Name">
                                                </div>

                                                <div class="select-cr">
                                                    <p>Select Scopes</p>
                                                    <div class="form-group">
                                                        <!--<div class="scr-select-all">
                                                            <input type="checkbox" id="scr-select-all" onselect="selectAll()">
                                                            <label for="">All</label>
                                                        </div>-->
                                                        <div v-for="scope in clientScopes" :key="scope.id" class="scr-checkbox">
                                                            <input type="checkbox" :value="scope.id" v-model="newClientRoles.client_scopes">
                                                            <label>{{scope.name}} ({{scope.description}})</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                
                                                <div class="cr-remark">
                                                    <label for="cr-remark-input">Remarks</label>
                                                    <input type="text" v-model="newClientRoles.description" placeholder="Enter Description">
                                                </div>
                                                        
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button v-on:click="reset" type="button" class="btn btn-danger"
                                                    data-dismiss="modal">Cancel
                                            </button>
                                            <button v-if="isEdit" v-on:click="createClientRole" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Update
                                            </button>
                                            <button v-else v-on:click="createClientRole" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          <!-- Create role Modal End -->
                          <!-- Create Modal For Showing Scopes Start-->

                          <div class="modal fade ce-assign-role" id="show-scopes" tabindex="-1" role="dialog"
                               aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="ce-role-assign-modal">View All Scopes</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="ce-content-inner">
                                    <li class="" v-for="scope in this.clientScopesForRole" :key="scope.id">{{scope.name}}</li>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button v-on:click="reset" type="button" class="btn btn-danger"
                                          data-dismiss="modal">Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Create Modal For Showing Scopes End -->
                            <!-- Assign Role Modal -->
                            <!--<div class="modal fade ce-assign-role" id="ce-assign-role" tabindex="-1" role="dialog"
                                 aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="ce-role-assign-modal">Assign role to client</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    Name
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        {{new_role.role_name}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    Assign Role To:
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        <Multiselect
                                                            v-model="selectedUsers"
                                                            :options="userData"
                                                            :showLabels="false"
                                                            label="text"
                                                            :searchable="true"
                                                            placeholder="Select Users"
                                                            :multiple="true"
                                                            :close-on-select="false"
                                                            :clear-on-select="false"
                                                            track-by="value"
                                                            >
                                                            <template slot="selection" slot-scope="{ values, isOpen }">
                                                                <span
                                                                class="multiselect__single"
                                                                v-if="values.length &amp;&amp; !isOpen"
                                                                >{{ values.length }} options selected</span
                                                                >
                                                            </template>
                                                        </Multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button v-on:click="resetNewRole" type="button" class="btn btn-danger"
                                                    data-dismiss="modal">Cancel
                                            </button>
                                            <button v-on:click="bulkAssign" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                            <!-- Assign Role Modal End -->
                        </div>
                    </div>

                    <div class="row new-list-table user-list-table">
                        <div class="col-12">
                            <div class="list-table-header">
                                <div class="list-header id">ID</div>
                                <div class="list-header name">Name</div>
                                <div class="list-header description">Description</div>
                                <div class="list-header description">App</div>
                              <div class="list-header description">Scopes Assigned</div>
                                <div class="list-header created">Created At</div>
                                <div class="list-header actions">Actions</div>
                            </div>

                            <div class="list-table-body">
                                <div class="list-row" v-for="(role,index) in clientRoles" :key="role.id">
                                    <div class="list-body id">{{index+1}}</div>
                                    <div class="list-body name">{{role.name}}</div>
                                    <div class="list-body description">{{role.description}}</div>
                                    <div class="list-body description">{{role.sso_clients ? role.sso_clients.name : 'SSO'}}</div>
                                  <div class="list-body email"><div v-if="role.client_scopes.length <= 2"><li v-for="scope in role.client_scopes" :key="scope.id">{{scope.name}}</li></div><div v-else><a href data-toggle="modal" data-target="#show-scopes" @click="loadScopes(role)">View All</a></div></div>
                                    <div class="list-body created">{{role.created_at}}</div>
                                    <div class="list-body actions">
                                        <div class="edit">
                                            <span name="Edit Role" @click="editRole(role)" title="EDIT ROLE" data-toggle="modal" data-target="#ce-create-role">
                                                <i class="cts-configure cts_recipient_icon"></i>
                                            </span>
                                        </div>
                                        <div class="delete">
                                            <span name="Delete Role" @click="deleteRole(role)" title="DELETE ROLE">
                                                <i class="cts-delete cts_recipient_icon"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                </div>
                <!-- ce-content-inner End ------------------------>

            </div>
            <!-- First col End -------------------------------->

        </div>
    </div>
</template>

<style>
.client-roles-wrap .roles-page-header .ce-create-role-btn-wrap {
    left: 180px;
}
#ce-create-role .modal-content .modal-body .cr-name, #ce-create-role .modal-content .modal-body .roles-client-select {
    margin-bottom: 15px;
}
#ce-create-role .modal-content .modal-body .cr-name label, #ce-create-role .modal-content .modal-body .cr-remark label, #ce-create-role .modal-content .modal-body .roles-client-select label {
    width: 25%;
}
#ce-create-role .modal-content .modal-body .cr-name input, #ce-create-role .modal-content .modal-body .cr-remark input, #ce-create-role .modal-content .modal-body .roles-client-select select {
    width: 75%;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    border-radius: 0.25rem;
    background: #f8f9fa;
    border: 1px solid #00000033;
    font-weight: 600;
}
#ce-create-role .modal-content .modal-body .scr-checkbox-wrap .scr-select-all label {
    background: transparent;
    border-top: none;
    background: #e4e5ea4d;
    margin-bottom: 0;
    cursor: default;
    opacity: 0.9;
}
#ce-create-role .modal-content .modal-body .scr-checkbox-wrap {
    margin-top: 15px;
    margin-bottom: 25px;
    border: 2px solid #e4e5ea4d;
    padding: 0px;
    padding-bottom: 5px;
    max-height: 200px;
    overflow-y: auto;
}
#ce-create-role .modal-content .modal-body .select-cr {
    margin-top: 26px;
}
#ce-create-role .modal-content .modal-body .select-cr p {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 14px;
}
#ce-create-role .modal-content .modal-body .select-cr input {
    display: inline-block;
    margin-top: 12px;
    cursor: pointer;
    margin-left: 8px;
    z-index: 2;
    position: relative;
}
#ce-create-role .modal-content .modal-body .select-cr label {
    padding-left: 35px;
    margin-top: -28px;
    padding-top: 13px;
    padding-bottom: 14px;
    display: block;
    font-size: 13px;
    margin-bottom: 3px;
    border-top: 1px solid #efefef;
    font-weight: 600;
    opacity: 0.7;
    z-index: 1;
    position: relative;
    background: #cbcccd70;
}
#ce-create-role .modal-content .modal-body .cr-remark {
    margin-bottom: 20px;
}
.ce-main-wrapper .ce-wrapper .ce-content .ce-content-inner .ce-create-role-btn-wrap .ce-btn-create-role-submit {
    width: 180px;
    height: 45px;
    background: #00ccff;
    color: #fff;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
}
.cts_recipient_icon{
    color:#2cc4ef;
    font-size:18px;
    cursor: pointer;
    margin: 7px;
}
.multiselect__tags .multiselect__single, .send_form .input_box input, .multiselect__tags .multiselect__placeholder {
    font-size: 14px !important;
    color: #656568 !important;
}
.multiselect__tags {
    border: 1px solid #bfbfbf !important;
    padding-left: 15px;
}
.multiselect__tags .multiselect__single {
    overflow: hidden;
}
.multiselect .multiselect__tags {
    height: 40px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
    import Api from '../includes/Api';
    import moment from 'moment';
    //import Multiselect from "vue-multiselect";

    export default {
      components: {
        //Multiselect,
      },
      data() {
        return {
          isEdit: 0,
          editIndex:0,
          newClientRoles: {
            name : null,
            description: null,
            sso_client_id: null,
            client_scopes:[]
          },
          clientScopes:[],
          clientRoles: [],
          clientList: [],
          clientScopesForRole: []
        }
      },
      methods: {
        createClientRole() {

          this.$confirm(
              {
                message: 'Are you sure you want to create this role?',
                button: {
                  no: "No",
                  yes: "Yes"
                },
                callback: confirm => {
                  if (confirm){

                    let root = this;
                    if ( root.newClientRoles.name === '' || root.newClientRoles.name === undefined || root.newClientRoles.name === null ){
                      Api.alert("warning", "Name cannot be empty.");
                    } else if ( root.newClientRoles.sso_client_id === '' || root.newClientRoles.sso_client_id === undefined || root.newClientRoles.sso_client_id === null ) {
                      Api.alert("warning", "Client cannot be empty.");
                    } else if ( root.newClientRoles.description === '' || root.newClientRoles.description === undefined || root.newClientRoles.description === null ) {
                      Api.alert("warning", "Description cannot be empty.");
                    } else if ( root.newClientRoles.client_scopes.length <= 0 ) {
                      Api.alert("warning", "Please select Scopes.");
                    } else{
                      Api.post('/save-client-role', root.newClientRoles).then((response) => {
                        if (response.data.status === 'success') {
                          if( this.newClientRoles.id === null && (response.data.data !== null || response.data.data !== undefined) ){
                            this.getAllClientRoles();
                          } else if ( this.newClientRoles.id !== null && (response.data.data !== null || response.data.data !== undefined) ) {
                            this.$set(this.clientRoles,this.editIndex,response.data.data);
                          }
                          this.reset();
                          Api.alert('success', response.data.message);
                        }
                      });
                    }

                  }
                }
              }
          )
        },
        getAllClientRoles() {
          let root = this;
          Api.get('/list-client-role').then((response) => {
            response.data.data.forEach(item => {
              item.created_at = moment(item.created_at).format('lll');
            });
            root.clientRoles = response.data.data;
          });
        },
        deleteRole(role) {
          this.$confirm(
              {
                message: 'Are you sure you want to delete this role?',
                button: {
                  no: "No",
                  yes: "Yes"
                },
                callback: confirm => {
                  if (confirm){
                    Api.get('/delete-client-role/'+role.id).then((resp) => {
                      if (resp.data.status === 'success') {
                        Api.alert('success', resp.data.message);
                      }
                      let index = this.clientRoles.indexOf(role);
                      if (index !== -1) {
                        this.clientRoles.splice(index, 1);
                      }
                    });
                  }
                }
              }
          )
        },
        editRole(role) {
          this.isEdit=1;
          this.editIndex=this.clientRoles.indexOf(role);
          Api.get('/list-client-role-by-id/'+role.id).then((resp) => {
            if (resp.data.status === 'success') {
              this.newClientRoles.id = resp.data.data.id;
              this.newClientRoles.name = resp.data.data.name;
              this.newClientRoles.description = resp.data.data.description;
              this.newClientRoles.sso_client_id = resp.data.data.sso_client_id;
              this.getClientScopes();
              resp.data.data.client_scopes.forEach(item => {
                this.newClientRoles.client_scopes.push(item.id);
              });
            }
          });
        },
        getAllClients(){
          let root = this;
          Api.get('/list-access-client').then((response) => {
            root.clientList = response.data.data;
          });
        },
        getClientScopes(){
          Api.get('/list-client-scope-by-client-id/'+ this.newClientRoles.sso_client_id ).then((resp) => {
            if (resp.data.status === 'success') {
              this.clientScopes = resp.data.data;
            }
          });
        },
        reset(){
          this.isEdit = 0;
          this.editIndex = null;
          this.clientScopesForRole = null;
          this.newClientRoles.id= null;
          this.newClientRoles.sso_client_id = null;
          this.newClientRoles.client_scopes = [];
          this.newClientRoles.name = null;
          this.newClientRoles.description = null;
          this.clientScopes = null;
        },
        loadScopes(role){
          this.clientScopesForRole = role.client_scopes;
        }
      },
      created() {
        this.getAllClientRoles();
        this.getAllClients();
      }
    }
</script>
